import React, { useEffect, useState } from 'react';

import { mobileEnquiry, useFeedbackHandler } from '+hooks';
import { IPayoutsModalProps, WithdrawalDetails } from '+types';
import { countryMobileCode, isMobileNumLengthValid, logError, mobileNumLengthMapping } from '+utils';

export default function useMobileValidation({ currency }: IPayoutsModalProps) {
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const [showMobileName, setShowMobileName] = useState(false);
  const [accountNameDisabled, setAccountNameDisabled] = useState(true);
  const [withdrawalDetails, setWithdrawalDetails] = useState<WithdrawalDetails>({
    amount: null,
    description: null,
    pin: '',
    mobileNo: '',
    operatorCode: '',
    username: '',
    mobileMoneyCode: ''
  });

  useEffect(() => {
    if (!isMobileNumLengthValid(withdrawalDetails.mobileNo, currency, mobileNumLengthMapping)) {
      setWithdrawalDetails(details => ({ ...details, username: '' }));
      setShowMobileName(() => false);
      return;
    }
    handleMobileValidation().catch(error => {
      feedbackInit({
        message: (error as { message: string }).message || 'An error occurred while validating mobile number',
        type: 'danger',
        componentLevel: true
      });
    });
  }, [withdrawalDetails.mobileNo]);

  useEffect(() => {
    if (!withdrawalDetails.mobileNo) {
      closeFeedback();
      return;
    }
    if (!isMobileNumLengthValid(withdrawalDetails.mobileNo, currency, mobileNumLengthMapping)) {
      const message = `Your mobile number should be ${mobileNumLengthMapping[currency as keyof typeof mobileNumLengthMapping]} digits.`;

      feedbackInit({
        message,
        type: 'warning',
        componentLevel: true
      });
      return;
    }
    closeFeedback();
  }, [withdrawalDetails.mobileNo, currency]);

  const handleMobileValidation = async () => {
    try {
      if (showMobileName) setShowMobileName(false);
      await validateMobileNumber(withdrawalDetails, setWithdrawalDetails);
      setShowMobileName(true);
    } catch (e) {
      feedbackInit({
        message: (e as { message: string }).message || 'An error occurred while validating mobile number',
        type: 'danger',
        componentLevel: true
      });
    }
  };

  const validateMobileNumber = async (
    withdrawalDetails: WithdrawalDetails,
    setWithdrawalDetails: (value: React.SetStateAction<WithdrawalDetails>) => void
  ) => {
    const phoneNumber = `${countryMobileCode[currency as keyof typeof countryMobileCode]}${withdrawalDetails.mobileNo}`;
    const { mobileMoneyCode } = withdrawalDetails;
    const data = { mobileMoneyCode, phoneNumber, currency };

    if (['GHS', 'KES'].includes(currency)) {
      try {
        setWithdrawalDetails(details => ({ ...details, bankEnquiry: { visible: true, message: 'Verifying mobile number...' } }));
        const response = (await mobileEnquiry(data)) as { data: { account_name?: string } };
        const mobileDetails = response.data;
        setWithdrawalDetails(details => ({
          ...details,
          isValidated: true,
          username: mobileDetails.account_name || ''
        }));
        if (!mobileDetails?.account_name) {
          setAccountNameDisabled(false);
        }
      } catch (error) {
        logError(error);
        setAccountNameDisabled(false);
      }
    }
  };

  const resetValidateMobileNumberDetails = () => {
    setWithdrawalDetails(details => ({
      ...details,
      isvalidated: false,
      mobileNo: '',
      username: '',
      operatorCode: ''
    }));
  };

  return {
    validateMobileNumber,
    withdrawalDetails,
    setWithdrawalDetails,
    showMobileName,
    setShowMobileName,
    handleMobileValidation,
    accountNameDisabled,
    resetValidateMobileNumberDetails
  };
}
