/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useRef, useState } from 'react';

import Modal from '+dashboard/Shared/Modal';
import { useFeedbackHandler, useGetFee, useMobileValidation } from '+hooks';
import { MobileMoneyServices } from '+services/mobile-money-services';
import useStore from '+store';
import { BankPayoutsModalProps, InitiateTransferType, ProcessTransferType, TransferData, WithdrawalDetails } from '+types';
import {
  backwardAmountInput,
  capitalizeRemovedash,
  cleanInput,
  countryMobileCode,
  currencyToCountryCode,
  formatAmount,
  formatWithCommas,
  history,
  isMobileNumLengthValid,
  logBreadCrumb,
  mobileNumLengthMapping,
  stripNonNumeric,
  twoFAInputValidation
} from '+utils';
import { breadCrumbEvents } from '+utils/bugsnag-events';

import WithdrawWarning from './WithdrawWarning';

export default function MobilePayoutsModal({
  close,
  currency,
  balance = 0,
  initiateTransfer,
  processTransfer,
  resendOTP,
  paymentRef,
  identifier,
  maxPayoutLimit,
  minPayoutLimit,
  get2FAType,
  type: modalType
}: BankPayoutsModalProps) {
  const defaultMerchant = useStore(store => store.defaultMerchant);
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const timerInterval = useRef<number | null>(null);
  const [type, setType] = useState('');
  const [codeSent, setCodeSent] = useState(true);
  const [time, setTime] = useState(0);
  const [twoFactorType, setTwoFactorType] = useState('otp');

  const { withdrawalDetails, setWithdrawalDetails, showMobileName, resetValidateMobileNumberDetails, accountNameDisabled } =
    useMobileValidation({
      currency,
      maxPayoutLimit,
      minPayoutLimit
    });

  const transferData: TransferData = {
    two_factor_type: twoFactorType,
    reference: '',
    destination: {
      type: 'mobile_money',
      amount: withdrawalDetails.amount,
      narration: withdrawalDetails.description || 'Withdrawal from Merchant Balance',
      currency,
      mobile_money: {
        operator: withdrawalDetails.operatorCode,
        mobile_number: `${countryMobileCode[currency as keyof typeof countryMobileCode] ?? ''}${withdrawalDetails.mobileNo ?? ''}`
      },
      customer: {
        name: withdrawalDetails.username
      }
    }
  };

  const { data: mobileMoneyOperatorData } = MobileMoneyServices.useFetchMobileMoneyNetwork({
    code: currencyToCountryCode[currency],
    errorMessage: 'There has been an error getting network providers. Please refresh the page.',
    enabled: !!currency,
    refetchOnCloseFeedbackError: true
  });

  useEffect(() => {
    if (codeSent && type === 'otpStage') {
      if (timerInterval.current) {
        clearInterval(timerInterval.current);
      }
      timerInterval.current = window.setTimeout(() => {
        if (time <= 0) {
          clearInterval(timerInterval.current!);
          setCodeSent(false);
        } else {
          setTime(time - 1);
        }
      }, 1000);
    }
    return () => clearInterval(timerInterval.current!);
  }, [time]);

  useEffect(() => {
    setTwoFactorType(modalType);
  }, [modalType]);

  useEffect(() => {
    get2FAType && get2FAType(twoFactorType);
    setWithdrawalDetails((details: WithdrawalDetails) => ({ ...details, pin: '' }));
  }, [twoFactorType]);

  useEffect(() => {
    if (maxPayoutLimit && parseFloat(withdrawalDetails?.amount?.toString() as string) > maxPayoutLimit) {
      feedbackInit({
        message: `Your maximum payout limit is ${formatAmount(maxPayoutLimit)} ${currency} per transaction.`,
        type: 'danger',
        componentLevel: true
      });
    } else {
      closeFeedback();
    }
  }, [withdrawalDetails.amount]);

  const setDisabled = () => {
    switch (type) {
      case 'withdrawAmount':
        const number = parseFloat(String(withdrawalDetails?.amount));
        if (number >= minPayoutLimit && number <= maxPayoutLimit) {
          if (number <= parseFloat(balance) - (parseFloat(fee) || 0)) return false;
        }
        return true;
      case 'otpStage':
        if (twoFAInputValidation(twoFactorType, withdrawalDetails.pin)) return false;
        return true;
      default:
        const operatorCodeLength = withdrawalDetails.operatorCode.length;
        const mobileNumLength = isMobileNumLengthValid(withdrawalDetails.mobileNo, currency, mobileNumLengthMapping);
        const isUsernameProvided = withdrawalDetails.username && withdrawalDetails.username.trim().length > 0;

        if (operatorCodeLength && mobileNumLength && isUsernameProvided) {
          return false;
        }

        return true;
    }
  };

  const { fee, fetchingFee, setFee } = useGetFee({ currency, currentAmount: withdrawalDetails.amount, channel: 'mobile_money' });

  const defaultWithdrawContent = () => {
    return (
      <div>
        <div className="form-group">
          <label htmlFor="operatorCode" className="withdraw-label">
            <span className="dark">Mobile Network</span>
          </label>
          <select
            data-testid="operatorCode"
            aria-label="select"
            className="form-control"
            name="operatorCode"
            onChange={e => {
              const selectedOperator = mobileMoneyOperatorData?.data?.find((operator: any) => operator.slug === cleanInput(e.target.value));
              if (selectedOperator) {
                const { slug, code } = selectedOperator;
                setWithdrawalDetails((details: WithdrawalDetails) => ({ ...details, operatorCode: slug, mobileMoneyCode: code }));
              }
            }}
          >
            <option value="">Select a Mobile Network</option>
            {mobileMoneyOperatorData?.data?.map((operator: any) => (
              <option value={operator.slug} key={operator.id}>
                {operator.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="mobileNo" className="withdraw-label">
            <span className="dark">Mobile Number</span>
          </label>
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">{`+${countryMobileCode[currency]}`}</div>
            </div>
            <input
              id="mobileNo"
              name="mobileNo"
              component="input"
              className="form-control"
              value={withdrawalDetails.mobileNo || ''}
              type="text"
              onChange={e => {
                const formattedInput = stripNonNumeric(e.target.value);
                setWithdrawalDetails((details: WithdrawalDetails) => ({ ...details, mobileNo: formattedInput }));
              }}
              placeholder="e.g 0123456789"
            />
          </div>
        </div>
        {['GHS', 'KES'].includes(currency) ? (
          <div>
            {showMobileName && (
              <div className="form-group">
                <label htmlFor="username" className="withdraw-label">
                  <span className="dark">Customer&apos;s Name</span>
                </label>
                <input
                  id="username"
                  value={withdrawalDetails.username}
                  className="form-control"
                  name="username"
                  placeholder="Customer's name"
                  disabled={accountNameDisabled}
                  onChange={e => {
                    const accountName = e.target.value;
                    setWithdrawalDetails((details: WithdrawalDetails) => ({ ...details, username: accountName }));
                  }}
                />
              </div>
            )}
          </div>
        ) : (
          <div className="form-group">
            <label htmlFor="username" className="withdraw-label">
              <span className="dark">Customer&apos;s Name</span>
            </label>
            <input
              id="username"
              value={withdrawalDetails.username}
              className="form-control"
              name="username"
              onChange={e => {
                const formattedInput = cleanInput(e.target.value);
                setWithdrawalDetails((details: WithdrawalDetails) => ({
                  ...details,
                  username: formattedInput
                }));
              }}
              placeholder="Customer's name"
            />
          </div>
        )}
        <span className="withdrawal_info p-2" style={{ marginBottom: '9px', display: 'block' }}>
          Please, ensure that the customer or recipient&apos;s details are correct to avoid any potential issue with the transaction.
        </span>
        <WithdrawWarning />
      </div>
    );
  };

  const withdrawAmountContent = () => {
    return (
      <>
        <div className="element-box withdraw-detail-container p-3">
          <div>
            <p>
              A{' '}
              <strong>
                minimum of {currency} {formatAmount(minPayoutLimit)}
              </strong>{' '}
              and a{' '}
              <strong>
                maximum of {currency} {formatAmount(maxPayoutLimit)}
              </strong>{' '}
              can be withdrawn in a single transaction.
            </p>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="amount" className="withdraw-label">
            <span className="dark">Amount to withdraw </span>
            <span className="small">
              Fee charged:{' '}
              {fetchingFee ? (
                <span
                  className="spinner-border spinner-border-sm"
                  style={{ opacity: '0.4', marginLeft: '5px' }}
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <span className="dark">{fee}</span>
              )}
            </span>
          </label>
          <input
            className="form-control"
            name="amount"
            type="text"
            inputMode="numeric"
            value={formatWithCommas(withdrawalDetails.amount || '')}
            placeholder="eg 1,000.00"
            onChange={e => {
              const inputValue = e.target.value.replace(/,/g, '');
              const formattedAmount = backwardAmountInput(cleanInput(inputValue));
              setWithdrawalDetails((details: WithdrawalDetails) => ({ ...details, amount: formattedAmount }));
            }}
          />
          <label htmlFor="amount" className="withdraw-label mt-2 small">
            <span>
              Minimum amount:{' '}
              <span className={`dark ${parseFloat(withdrawalDetails.amount) < minPayoutLimit && 'red'}`} id="min">
                {currency} {formatAmount(minPayoutLimit)}
              </span>
            </span>
            <span>
              Available balance:{' '}
              <span className={`dark ${parseFloat(withdrawalDetails.amount) > balance && 'red'}`}>
                {currency} {formatAmount(balance)}
              </span>
            </span>
          </label>
        </div>
        <div className="form-group">
          <label htmlFor="description" className="withdraw-label">
            <span className="dark">
              Description <span style={{ opacity: 0.7 }}>(optional)</span>
            </span>
          </label>
          <input
            rows={2}
            maxLength={150}
            value={withdrawalDetails.description || ''}
            className="form-control"
            name="description"
            onChange={e => {
              const formattedInput = cleanInput(e.target.value);
              setWithdrawalDetails((details: WithdrawalDetails) => ({ ...details, description: formattedInput }));
            }}
            placeholder="Enter a description"
          />
        </div>
      </>
    );
  };

  const otpContent = () => {
    let email = defaultMerchant?.userEmail;
    if (email?.includes('@')) {
      email = email.split('@');
      email = `${email[0].slice(0, 3)}*******@${email[1]}`;
    }
    let inputLabel = 'One Time PIN (OTP)';
    let inputPlaceholder = '';
    let AuthenticationText;
    if (twoFactorType === 'totp') {
      inputLabel = 'Authentication Code';
      inputPlaceholder = 'Enter authentication code';
      AuthenticationText = (
        <>
          To proceed, enter the authorization code from your <b>authenticator app</b> in the space provided below to confirm this
          transaction.
        </>
      );
    } else if (twoFactorType === 'totp_recovery_code') {
      inputLabel = 'Recovery Code';
      inputPlaceholder = 'Enter recovery code';
    }
    return (
      <div className="bankForm-container">
        <div className="element-box mb-2 p-0">
          <div>
            {twoFactorType === 'totp_recovery_code' ? (
              <div className="saved-bank-container">
                <p>
                  Can&apos;t use your authenticator app? Enter one of your previously saved recovery codes to validate this transaction.
                </p>
              </div>
            ) : (
              <div className="otp-form-container" style={{ color: '#F3F4F8', fontWeight: 400, fontSize: '12px' }}>
                <div className="otp-form-summary">
                  <p>Some important details of your payout:</p>
                  <div className="form-summary-item">
                    <span>Amount</span>
                    <span>
                      {' '}
                      {formatAmount(withdrawalDetails.amount || 0)} {currency}
                    </span>
                  </div>
                  <div className="form-summary-item">
                    <span>Customer&apos;s name </span>
                    <span> {transferData.destination.customer.name || 'Not Provided'}</span>
                  </div>
                  <div className="form-summary-item">
                    <span>Type</span>
                    <span> {capitalizeRemovedash(transferData.destination.type)}</span>
                  </div>
                  <div className="form-summary-item">
                    <span>Current Balance</span>
                    <span>
                      {' '}
                      {formatAmount(balance || 0)} {currency}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <p className="auth-info-text">
          {twoFactorType === 'otp' ? (
            <>
              To proceed, enter the OTP (one-time PIN) that was sent to your email <b>({email})</b>.
            </>
          ) : (
            <>{AuthenticationText}</>
          )}
        </p>
        <label htmlFor="amount">
          <strong> {inputLabel}</strong>
        </label>
        <input
          type="number"
          name="pin"
          maxLength={7}
          value={withdrawalDetails.pin || ''}
          autoComplete="one-time-code"
          placeholder={inputPlaceholder}
          onChange={e => {
            const formattedInput = cleanInput(e.target.value);
            setWithdrawalDetails((details: WithdrawalDetails) => ({ ...details, pin: formattedInput }));
          }}
        />
        {twoFactorType === 'totp' && (
          <div className="recovery-code">
            Can&apos;t access authenticator app?{' '}
            <button type="button" onClick={() => setTwoFactorType('totp_recovery_code')} className="recovery-code-btn">
              Confirm using recovery codes
            </button>
          </div>
        )}
        {twoFactorType === 'otp' && (
          <label htmlFor="amount" className="withdraw-label mt-2 small">
            <span>
              <span className="dark">If you didn&apos;t receive a code? </span>
              {!codeSent ? (
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={async () => {
                    setCodeSent(true);
                    setTime(30);
                    await resendOTP.mutateAsync(
                      {
                        identifier
                      },
                      {
                        onError: error => {
                          feedbackInit({
                            message: error.response?.data?.message || 'Failed to resend OTP, Please try again',
                            type: 'warning',
                            componentLevel: true
                          });
                        }
                      }
                    );
                  }}
                >
                  Resend code.
                </button>
              ) : (
                <span style={{ marginLeft: '7px' }}>Resend code in {time} secs.</span>
              )}
            </span>
          </label>
        )}
      </div>
    );
  };

  const getOtpStageDescription = () => {
    return <p>Please review the withdrawal information and confirm that you want to proceed with this withdrawal.</p>;
  };

  const getOtpStageFirstBtn = () => {
    if (twoFactorType === 'totp_recovery_code') {
      return () => {
        setTwoFactorType('totp');
      };
    }
    return undefined;
  };

  const switchWithdrawModal = (kind: string) => {
    let content;
    switch (kind) {
      case 'withdrawAmount':
        content = {
          heading: `Withdraw Funds to Mobile Money`,
          description: 'Please enter the details of the transaction to proceed.',
          content: withdrawAmountContent(),
          secondButtonText: 'Next',
          firstButtonText: 'Back',
          firstButtonAction: () => {
            setType('init');
            resetValidateMobileNumberDetails();
          },
          secondButtonAction: async () => {
            logBreadCrumb({ event: breadCrumbEvents.balances.attemptWithdrawButton('Next', 'mobile money') });
            await (initiateTransfer as InitiateTransferType).mutateAsync(transferData);
            setType('otpStage');
            setTime(30);
          }
        };
        break;
      case 'otpStage':
        content = {
          heading: `Confirm ${twoFactorType === 'totp_recovery_code' ? 'using recovery code' : 'Withdrawal'}`,
          description: getOtpStageDescription(),
          content: otpContent(),
          firstButtonText: twoFactorType === 'totp_recovery_code' ? 'Back' : undefined,
          secondButtonText: 'Yes, Confirm',
          firstButtonAction: getOtpStageFirstBtn(),
          secondButtonAction: async () => {
            const verifyData = {
              payment_reference: paymentRef,
              auth_data: {
                two_factor_type: twoFactorType,
                identifier,
                code: withdrawalDetails.pin
              }
            };
            await (processTransfer as ProcessTransferType).mutateAsync(verifyData);
          },
          completedHeading: 'Success',
          completedDescription: `You have successfully withdrawn funds to a ${currency} mobile money number.`,
          completedActionText: 'View payment details',
          completedAction: () => history.push(`/dashboard/payouts/${paymentRef}`)
        };
        break;
      default:
        content = {
          heading: `Mobile Money Withdrawal`,
          description: 'Please enter the details of the transaction to proceed.',
          content: defaultWithdrawContent(),
          secondButtonText: 'Next',
          secondButtonAction: () => {
            closeFeedback();
            setType('withdrawAmount');
          }
        };
        break;
    }
    return {
      size: 'md',
      close: () => {
        setType('init');
        setFee('0.00');
        setWithdrawalDetails({
          bankEnquiry: {
            visible: null,
            message: null,
            type: null
          }
        });
        close();
      },
      secondButtonDisable: setDisabled(),
      secondButtonActionIsTerminal: kind === 'otpStage',
      ...content
    };
  };

  return <Modal {...switchWithdrawModal(type)} />;
}
